body {
  background-color: white;
}

h1,
h2{
  color: #4a89a2;
  font-size: 1.5rem;
  text-decoration: underline;
  margin-left: 25%;
}

.box-blue{
  background: linear-gradient(to bottom, rgba(82,148,199,1) 0%, rgba(37,104,133,1) 38%, rgb(24, 82, 102) 61%, rgb(20, 78, 97) 74%, rgba(20,63,82,1) 100%);
  color: white;

  h1,
  h2{
    color: $white;
    margin-left: 75%;
  }
}

@media (max-width: 575px) {
  h1,
  h2{
    margin-left: 0;
  }
}

.whoweare-end{
  text-align: center;
  font-size: 1.5rem;
  margin-top: 3rem;
}

.menu-link a{
  color: white;

  &:hover{
    color:turquoise;
  }
}

p{
  font-size: 14px;
  text-align: justify;
}

.img-cat{
  width: 217px;
  height: 209px;
}


.logo-code-by-cats-phone{
  width: 279px;
  height: 63px;
}

.color-white{
  color: white;
}

.img-project{
  width: 123px;
  height: 195px;
}

.logo-words{
  font-family: Anton, sans-serif;
  line-height: 0.7;
  font-size: 60px;
  color: #41452d;
}

.img-obtineo{
  width: 401px;
  height: 108px;
}

.img-obeyka{
  width: 107px;
  height: 128px;
}

.logo-cat-small{
  width: 186px;
  height: 41px;
}

.img-obtineo-phone{
  width: 290px;
  height: 68px
}

.img-footer-cat{
  width: 175px;
  height: 36px;
}


/* Style the navbar */
#navbar {
  overflow: hidden;
  background-color: #185266;
  color: white;
  z-index: 9000;
  border-bottom: white solid 1em;
}

/* Navbar links */
#navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.logo-hide .logo-code-by-cats{
  display: none !important;
}

.logo-cat-small{
  display: none;
}

.sticky .logo-cat-small{
  display: block;
}

.row{
  margin-left: 0;
  margin-right: 0;
}

.img-project-phone{
  width: 123px;
  height: 163px;
}

.material-icons-hamburguer{
  display: none;
}

@media (max-width: 575px) {
  .material-icons-hamburguer {
    display: block;
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .logo-code-by-cats-phone {
    width: 229px;
    height: 50px;
  }
}

footer{
  a,
  a:visited{
    color: $white;
  }
  a:active,
  a:hover
  {
    color: $warning;
  }
}